import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="enquiries"
export default class extends Controller {
  connect() {
    $(".EnquiryForm").validate({
      rules: {
        "inquiry[name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 50
        },
        "inquiry[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "inquiry[message]": {
          required: true,
        }
      },
      messages: {
        "inquiry[name]": {
          required: "Name is required.",
          nameregex: 'Name can only consist of alphabets.',
          minlength: 'Name must be 2-50 characters long.',
          maxlength: 'Name must be 2-50 characters long.'
        },
        "inquiry[email]": {
          required: 'You must provide your email.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "inquiry[message]": {
          required: 'Comment or Message is required.',
        }
      }
    });

    $(".EnquiryFormMessage").validate({
      rules: {
        "inquiry[name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 50
        },
        "inquiry[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "inquiry[message]": {
          required: true,
        }
      },
      messages: {
        "inquiry[name]": {
          required: "Name is required.",
          nameregex: 'Name can only consist of alphabets.',
          minlength: 'Name must be 2-50 characters long.',
          maxlength: 'Name must be 2-50 characters long.'
        },
        "inquiry[email]": {
          required: 'You must provide your email.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "inquiry[message]": {
          required: 'Comment or Message is required.',
        }
      }
    });

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');
  }
}
