import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user"
export default class extends Controller {
  connect() {
    $(".UsersForm").validate({
      rules: {
        "user[username]": {
          required: true,
          minlength: 3,
          noSpecialChars: true
        },
        "user[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "user[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "user[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "user[password]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        },
        "user[password_confirmation]": {
          required: true,
        },
      },
      messages: {
        "user[username]": {
          required: "Username is required.",
          minlength: 'Username must Contains 3 atleast characters',
          noSpecialChars: 'Your Username can only contain letters and numbers'
        },
        "user[first_name]": {
          nameregex: 'The first name can only consist of alphabets.',
          required: 'The first name is required.',
          minlength: 'The first name must be 2-40 characters long.',
          maxlength: 'The first name must be 2-40 characters long.'
        },
        "user[last_name]": {
          nameRegex: 'The last name can only consist of alphabets.',
          required: 'The last name is required.',
          minlength: 'The last name must be 2-40 characters long.',
          maxlength: 'The last name must be 2-40 characters long.'
        },
        "user[email]": {
          required: 'You must provide your email.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "user[password]": {
          required: 'The password is required.',
          minlength: 'The password must be 8-15 characters long.',
          maxlength: 'The password must be 8-15 characters long.',
          passwordregex: 'The password must contain at least one letter, one digit, one special character, and be at least 8 characters long'
        },
        "user[password_confirmation]": {
          required: 'Confirm password is required.',
        },
      }
    });

    $(".UsersLogInForm").validate({
      rules: {
        "user[login]": {
          required: true,
          email: true,
          emailregex: true
        }
        // "user[password]": {
        //   required: true,
        //   minlength: 8,
        //   maxlength: 15,
        //   passwordregex: true
        // }
      },
      messages: {
        "user[login]": {
          required: 'You must provide your email.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        }
        // "user[password]": {
        //   required: 'The password is required.',
        //   minlength: 'The password must be 8-15 characters long.',
        //   maxlength: 'The password must be 8-15 characters long.',
        //   passwordregex: 'The password must contain at least one letter, one digit, one special character, and be at least 8 characters long'
        // }
      }
    });

    $(".new_user").validate({
      rules: {
        "user[email]": {
          required: true,
          email: true,
          emailregex: true
        }
      },
      messages: {
        "user[email]": {
          required: 'You must provide your email.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        }
      }
    });

    $('.PasswordForm').validate({
      rules: {
        "user[password]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        },
        "user[password_confirmation]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        }
      },
      messages: {
        "user[password]": {
          required: 'The password is required.',
          minlength: 'The password must be 8-15 characters long.',
          maxlength: 'The password must be 8-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        },
        "user[password_confirmation]": {
          required: 'The password is required.',
          minlength: 'The password must be 8-15 characters long.',
          maxlength: 'The password must be 8-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        }
      }
    })

    $.validator.addMethod("noSpecialChars", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
    }, "Your username contains invalid characters.");

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');

    $.validator.addMethod('passwordregex', function(value, element) {
      return this.optional(element) || /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/.test(value);
    }, 'Must follow the password pattern.');

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    $('input').keyup(function(){
      $('.error-password, .error-confirm').html('')
    })
    $('.register-btn, .forgot-password-button').click(function(){
      var password = $('#user_password').val()
      var confirm_password = $('#user_password_confirmation').val()
      if(confirm_password != password){
        $('.error-confirm').html('Password does not match !!!')
        return false;
      }
    });
  }
}
